import {Component} from '@angular/core';
import {DomSanitizer, SafeResourceUrl, Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {NgbModal, NgbModalRef, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ResizeEvent} from 'angular-resizable-element';
import {from, of, Observable} from "rxjs";
import {catchError, delay, map, startWith, switchMap, tap} from "rxjs/operators";
import {HttpParams} from '@angular/common/http';

import {environment} from '../environments/environment';

import {DoiAction, DoiAppView, DoiAppSettings, DoiModalResult, DoiLabeledValue, DoiLogLevel, DoiObjectView, DoiService, DoiObject, DoiObjectRef} from './doi/DoiModule';
import {DoiSearchResult, DoiSearchService} from './doi-search/DoiSearchModule';
import {DoiThemeService, DoiTheme} from './doi-theme/DoiThemeModule';
import {DoiNavigatorAppView, DoiNavNode} from './doi-navigator/DoiNavigatorModule';

import {AppInfo} from './klara/KlaraModule';

import {ArchiveOrigService} from './archiveorig/ArchiveOrigModule';
import {KlaraDialogTopView, KlaraTopView} from './klara/KlaraModule';
import {ProcessActTypeService} from './processacttype/ProcessActTypeModule';
import {ProcessService} from './process/ProcessModule';
import {ProcessStructureService, ProcessStructureSelectable} from './processstructure/ProcessStructureModule';
import {SystemInfoObject, SystemInfoService, SystemInfoPart} from './systeminfo/SystemInfoModule';
import {UnitService} from './unit/UnitModule';

import {ArchiveOrigNodeChildSupplier} from './navigator/ArchiveOrigNodeChildSupplier';
import {StructureFolderNodeChildSupplier} from './navigator/StructureFolderNodeChildSupplier';
import {StructureFolderDialogNodeChildSupplier} from './navigator/StructureFolderDialogNodeChildSupplier';
import {ProcessNodeChildSupplier} from './navigator/ProcessNodeChildSupplier';
import {ProcessStructureNodeChildSupplier} from './navigator/ProcessStructureNodeChildSupplier';
import {UnitFolderNodeChildSupplier} from './navigator/UnitFolderNodeChildSupplier';
import {UnitNodeChildSupplier} from './navigator/UnitNodeChildSupplier';

import {SearchDialogView} from './search/SearchDialogView';

import {AppBaseView} from './AppBaseView';

declare let navigationEnd: Function;

@Component({
	selector: 'app',
	templateUrl: 'AppDialogView.html'
})
export class AppDialogView extends AppBaseView
{
	name = 'AppView';

	processActTypeService: ProcessActTypeService;
	processService: ProcessService;
	processStructureService: ProcessStructureService;

	/**
	 * The process structures available to KlaraDialog, and the currently selected.
	 * The pending ID is used when the psid parameter is received before the processStructureList.
	 */
	processStructureList: ProcessStructureSelectable[];
	private _processStructure: ProcessStructureSelectable = null;
	pendingProcStructID: number = null;

	constructor(
		router: Router,
		doi: DoiService,
		themes: DoiThemeService,
		titleService: Title,
		searchService: DoiSearchService,
		systemInfoService: SystemInfoService,
		processService: ProcessService,
		archiveOrigService: ArchiveOrigService,
		processActTypeService: ProcessActTypeService,
		processStructureService: ProcessStructureService,
		unitService: UnitService
	)
	{
		super(router, doi, themes, titleService, searchService, systemInfoService, processService,
			archiveOrigService, processActTypeService, processStructureService,
			unitService);

		this.processActTypeService = processActTypeService;
		this.processService = processService;
		this.processStructureService = processStructureService;

		this.actions.add(
			new DoiAction(this, 'Klara', 'warehouse-alt', 'Visa i Klara', 'Visa sidan i Klara Arkivsök')
				.executeHandler(() => this.actionKlaraWeb())
				.enabledHandler(() => this.actionKlaraWebAvailable()),
		);

		this.actions.add(
			new DoiAction(this, 'KlaraEdit', 'far-pen', 'Ändra', 'Visa sidan för redigering i Klara Arkivsök')
				.executeHandler(() => this.actionKlaraWeb(true))
				.enabledHandler(() => this.actionKlaraWebAvailable(true)),
		);

		this.actions.get('Navigator').icon = 'fal-bars';
	}

	get processStructure(): ProcessStructureSelectable
	{
		return this._processStructure;
	}

	set processStructure(processStructure: ProcessStructureSelectable)
	{
		this._processStructure = processStructure;
	}

	/**
	 * Invoked when both appInfo and sytemInfo has been received.
	 * Builds the navigator and refreshes the PS list.
	 */
	infoReceived()
	{
		this.buildNavigator();
		this.refreshProcessStructureList();
	}

	/**
	 * Switch to Klara Web UI.
	 */
	actionKlaraWeb(edit = false)
	{
		let url = this.actionKlaraWebLink(edit);
		if (url) {
			let w = window.open(url, 'klaraweb');
			if (edit)
				w.addEventListener("hashchange", function () { this.location.reload() }, false);
		}
	}

	/**
	 * Test if switching to Klara Web should be available
	 */
	actionKlaraWebAvailable(edit = false): boolean
	{
		if (!this.appInfo || !this.appInfo.hasFeatureWeb())
			return false;

		if (edit) {
			if (!(this.activeView instanceof DoiObjectView))
				return false;
			var objectView = this.activeView as DoiObjectView<DoiObject>;
			if (!this.canEdit(objectView.object))
				return false;
		}

		return true;
	}

	/**
	 * Return a link to the corresponding page in KlaraWebUI.
	 */
	actionKlaraWebLink(edit = false): string
	{
		if (!this.appInfo || !this.appInfo.hasFeatureWeb())
			return null;

		var klaraTopView = this.activeView as KlaraTopView;
		let klaraPathAvailable = klaraTopView && klaraTopView.klaraPath;
		let klaraPath = klaraPathAvailable != undefined ? klaraTopView.klaraPath() : null;
		let editSfx = edit ? '?edit=true&fromdialog=true' : '';

		if (klaraPath != null && this.lastUrl) {
			if (klaraPath == '')
				return this.urlContext()+'/webui/index.html#'+ this.lastUrl + editSfx;
			else
				return this.urlContext()+'/webui/index.html#'+ klaraPath + editSfx;
		} else {
			return this.urlContext()+'/webui/index.html' + editSfx;
		}
	}

	/**
	 * Return the app container classes.
	 */
	appContainerClasses(): string
	{
		return [ super.appContainerClasses(), 'dialog' ].join(' ');
	}

	/**
	 * Return the application title suffix.
	 */
	appTitleSuffix(): string
	{
		return 'Dialog';
	}

	/**
	 * Invoked when a user login or logout is completed.
	 * Overridden to refresh available process structures.
	 */
	authenticatedStateChanged(loggedIn: boolean)
	{
		super.authenticatedStateChanged(loggedIn);

		this.refreshProcessStructureList();
	}

	/**
	 * Build the navigator.
	 */
	buildNavigator()
	{
		this.navRoot.addChild(
			new DoiNavNode('Struktur', '/start')
				.setNodeClass('node-structure')
				.setChildNodeSupplier(
					new StructureFolderDialogNodeChildSupplier(this.processService,
							new ProcessNodeChildSupplier(this.processService, this.processActTypeService),
							() => this.processStructure ? this.processStructure.procStructID : null
					)
				)
				.setSticky(true).setExpandedAlways(true)
		).setExpandedAlways(true);
	}

	/**
	 * Return the FontAwesome style class (fas, far, fad, etc) to use if not specified by the icon name.
	 * Overridden to return 'far'.
	 */
	iconStyleClass(): string
	{
		return 'far';
	}

	/**
	 * Test if selecting a process structure is supported. Supported if there are more than one PS in the list of available.
	 */
	isProcessStructureSelectable()
	{
		return this.processStructureList && this.processStructureList.length > 1;
	}

	/**
	 * Invoked by an object view to indicate that an object is visible.
	 * Overridden to expand the object path.
	 */
	objectVisible(object: DoiObject, subviewName?: string): void
	{
		if (this.navigatorVisible() && this.navRoot) {
			let path = object.objectRefPath();
			if (path) {
				let node = null;
				//	Find PS and trim path.
				let ix = 0;
				for (let i = 0; i < path.length; ++i) {
					if (path[i].objectType == 'ArchiveOrig')
						continue;
					if (path[i].objectType == 'ProcessStructure') {
						ix = i+1;
						break;
					}
				}
				path = path.slice(ix);
				//	Expand the start node.
				node = this.navRoot.findChildByUrl('/start');
				if (node) {
					node.expandPath(path);
				}
			}
		}
	}

	/**
	 * Refresh the list of available process structures and the selected.
	 */
	refreshProcessStructureList()
	{
		//	May be invoked befor constructor is completed.
		if (!this.processStructureService)
			this.processStructureService = this.doi.brokerService('ProcessStructure') as ProcessStructureService;

		this.processStructureService.readSelectable().subscribe(
			psList => {
				this.processStructureList = psList;
				this.selectProcessStructure(this.pendingProcStructID);
				this.urlNavigated(this.lastUrl);
			}
		)
	}

	/**
	 * Return the search field placeholder.
	 */
	searchFieldPlaceholder()
	{
		if (this.systemInfo && this.systemInfo.searchFieldPlaceholder)
			return this.systemInfo.searchFieldPlaceholder;
		else
			return "Vad letar du efter?";
	}

	/**
	 * Test if process codes should be visible.
	 */
	showProcessCodes()
	{
		if (this.systemInfo && !this.systemInfo.showProcessCode)
			return false;
		else
			return true;
	}

	/**
	 * Select a process structure. If the specified ID is selectable, it is used, otherwise one is choosen from the available.
	 * Invoked when the user selects a PS in the navigator PS selector. The method navigates to the start page unless it is
	 * already active.
	 * This method is also invoked by object views, when they refresh their data and receives a PS ID.
	 * In this case, navigation to Start occurs only if the specified PS isn't selectable.
	 */
	selectProcessStructure(procStructID?: number, navToStart: boolean = false)
	{
		this.doi.log.fine('selectProcessStructure: '+procStructID);

		if (!this.processStructureList) {
			this.pendingProcStructID = procStructID;
			return;
		}

		this.pendingProcStructID = null;

		this.navigatorCloseUndocked();

		let oldPS = this.processStructure;
		let newPS = null;

		//	If no PS ID specified, fetch locally saved.

		if (!procStructID) {
			try {
				procStructID = this.storageGetItem('procStructID', null);
			} catch (ignored) {}
		}

		//	Find the PS in the list of available.

		if (procStructID) {
			let ps = this.processStructureList.find((p) => p.procStructID == procStructID);
			if (ps)
				newPS = ps;
		}

		//	Not found, then choose the preferred.

		let forcedPS = !newPS;

		if (!newPS) {
			let list = [...this.processStructureList];
			list.sort((ps1, ps2) => ps1.compareTo(ps2));
			if (list.length)
				newPS = list[0];
		}

		//	Select and save locally if the wanted was accepted.

		this.processStructure = newPS;

		if (this.processStructure && this.processStructure.procStructID == procStructID)
			this.storageSetItem('procStructID', procStructID);

		//	Check if forced navigation to start due to auto selected (forced) PS.

//		if (forcedPS)
//			navToStart = true;

		//	Navigate to start if requested or forced. May instead navigate to login if not logged in and there is no PS available, i e no externally published.

		if (navToStart) {
			if (this.processStructure || this.doi.auth.isAuthenticated())
				this.doi.router.navigate(['/start']);
			else
				this.doi.router.navigate(['/login']);
		}

		//	Refresh the navigator if the PS was changed.

		if (!oldPS || !newPS || oldPS.procStructID != newPS.procStructID) {
			let structNode = this.navRoot.findChildByUrl('/start');
			if (structNode)
				structNode.refresh();
		}

		//	Refresh start page if active.

		if (this.activeView.name == 'StartView')
			this.activeView.refresh();
	}
}
