<link *ngFor="let url of themes.cssUrls()" rel="stylesheet" [attr.href]="url">

<div class="doi-app-container" [ngClass]="appContainerClasses()">

	<div class="doi-page-header" role="banner">
		<div class="doi-page-header-title">
			<ul doi-toolbar>
				<li doi-tool class="navigator-tool" [target]="this" action="Navigator"></li>
			</ul>
			<h1>
				<a routerLink="/">
					<div class="app-title">
						<span class="prefix">{{appTitlePrefix()}}</span>
						<span class="suffix">{{appTitleSuffix()}}</span>
					</div>
				</a>
			</h1>
		</div>
		<ul doi-toolbar class="doi-page-header-tools">
			<li doi-tool class="skip-to-content doi-hidden-xs-down" [target]="this" action="SkipToContent" [compact]="true"></li>
			<li class="search-tool" *ngIf="searchToolVisible()"><doi-search-tool class="doi-hidden-md-down" [size]="20" [placeholder]="searchFieldPlaceholder()" (search)="onSearch($event)"></doi-search-tool></li>
		</ul>
		<ul doi-toolbar class="doi-page-header-tools">
			<li doi-tool class="doi-hidden-lg-up" *ngIf="searchToolVisible()" [target]="this" action="Search" icon="far-search"></li>
			<section class="klaraweb-tools">
				<li *ngIf="actionKlaraWebAvailable()" doi-tool anchorTarget="klaraweb" [target]="this" action="Klara"></li>
				<li *ngIf="actionKlaraWebAvailable(true)" doi-tool anchorTarget="klaraweb" [target]="this" action="KlaraEdit"></li>
			</section>
			<doi-object-bookmarks-tool [target]="this" [bookmark]="true" [compact]="false"></doi-object-bookmarks-tool>
			<li doi-tool *ngIf="!authenticated()" [target]="this" action="Login"></li>
			<li doi-tool *ngIf="authenticated()" [target]="this" dropdown="true" title="{{authenticatedName()}}" icon="far-user">
				<ul doi-dropdown-menu class="dropdown-menu-right" [target]="this" [actions]="menuUserActionNames(false)"></ul>
			</li>
			<!--
			<li doi-tool [target]="this" action="ViewRefresh" [compact]="true"></li>
			-->
		</ul>
	</div>

	<div class="doi-app-pane-container" [ngClass]="navigatorContainerClasses()">
		<div id="navPane" class="doi-app-pane doi-navigator-pane doi-background-secondary d-print-none"
			 [ngClass]="navigatorPaneClasses()"
			 [doi-hidden]="!navigatorVisible()"
			 mwlResizable [resizeEdges]="navResizeEdges()" (resizing)="navResizing($event)" (resizeEnd)="navResizeEnd($event)" [resizeCursorPrecision]="8"
			 (click)="navigatorPaneClick($event, 'processStructureSelector', 'processStructureMenu')">
			<div class="doi-navigator-wrapper doi-background-secondary"
				 (click)="navigatorClick($event, 'processStructureSelector', 'processStructureMenu')">
				<div *ngIf="!isProcessStructureSelectable()" class="doi-navigator-header doi-bar">
					<ul doi-toolbar>
						<li doi-tool [target]="this" action="NavigatorDocked" [compact]="true"></li>
						<li doi-tool [target]="this" action="NavigatorClose" [compact]="true"></li>
					</ul>
				</div>
				<div *ngIf="isProcessStructureSelectable()" class="navigator-ps-selector">
					<div class="dropdown-trigger-pane">
						<div class="doi-navigator-header header">
							<span class="label">Klassificeringsstruktur</span>
							<ul doi-toolbar>
								<li doi-tool [target]="this" action="NavigatorDocked" [compact]="true"></li>
								<li doi-tool [target]="this" action="NavigatorClose" [compact]="true"></li>
							</ul>
						</div>
						<button class="btn dropdown-toggle expand-button" type="button" id="processStructureSelector" data-bs-toggle="dropdown" data-target="ps-dropdown" aria-haspopup="true" aria-expanded="false">
							<span><i class="far fa-sitemap"></i>{{processStructure.name}}</span>
							<i class="fas fa-chevron-down dropdown-show"></i>
							<i class="fas fa-chevron-up dropdown-hide"></i>
							<div id="#ps-dropdown" class="dropdown">
								<ul id="processStructureMenu" class="dropdown-menu" aria-labelledby="processStructureSelector">
									<li class="dropdown-item" *ngFor="let ps of processStructureList" (click)="selectProcessStructure(ps.procStructID, true)"><a href="">{{ps.name}}</a></li>
								</ul>
							</div>
						</button>
					</div>
				</div>
				<div id="nav" class="doi-navigator">
					<ul doi-nav-root [root]="navRoot" (select)="navNodeSelected($event)"></ul>
				</div>
				<div class="doi-navigator-footer">
					<li doi-tool [target]="this" action="Settings"></li>
					<div class="version doi-hidden-sm-down" *ngIf="appInfo" ngbTooltip="{{appInfo.version.implementation}}">Version {{appInfo.version.specification}}{{development?" DEV":""}}</div>
				</div>
			</div>
		</div>
		<div class="doi-app-pane container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="doi-alert alert alert-danger theme-alert-dialog">
						<span class="doi-alert-message">Detta tema är inte anpassat för Klara Dialog. Kontakta systemansvarig.</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<doi-alert></doi-alert>
				</div>
			</div>
			<div class="doi-page-content" role="main">
				<router-outlet (activate)="viewActivate($event)" (deactivate)="viewDeactivate($event)"></router-outlet>
			</div>
		</div>
	</div>

	<div class="doi-page-footer container-fluid" role=”contentinfo”>
		<div class="doi-storage-consent" *ngIf="localStorageChoice() == null && systemInfo?.cookieConsentText">
			<div class="row">
				<div class="col-12">
					<p>{{systemInfo?.cookieConsentText}}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="doi-dialog-buttons">
						<button class="btn btn-primary" (click)="localStorageConsent(true)">{{systemInfo?.cookieConsentButtonText}}</button>
						<button class="btn btn-danger" (click)="localStorageConsent(false)">{{systemInfo?.cookieDeclineButtonText}}</button>
					</div>
				</div>
			</div>
		</div>
		<div class="doi-debug" *ngIf="settings.debugMode">
			<div class="row">
				<div class="col-1">
					<span class="doi-hidden-not-xl">XL</span>
					<span class="doi-hidden-not-lg">LG</span>
					<span class="doi-hidden-not-md">MD</span>
					<span class="doi-hidden-not-sm">SM</span>
					<span class="doi-hidden-not-xs">XS</span>
					<span class="doi-hidden-landscape">/P</span>
					<span class="doi-hidden-portrait">/L</span>
				</div>
				<div class="col-2">{{['All','Int','Ext'][publishLevel]}}</div>
				<div class="col-8">{{lastUrl}}</div>
			</div>
		</div>
	</div>

</div>