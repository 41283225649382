import {EMPTY, Observable} from 'rxjs';
import {map} from "rxjs/operators";

import {DoiIconMapper, DoiObjectRef, DoiSelectionCriteria} from '../doi/DoiModule';
import {DoiNavNode, DoiNavNodeChildSupplier} from '../doi-navigator/DoiNavigatorModule';

import {ProcessService, ProcessObject} from '../process/ProcessModule';

/**
 * A navigator node child supplier for the structure folder node containing top level processes.
 */
export class StructureFolderDialogNodeChildSupplier extends DoiNavNodeChildSupplier
{
	constructor(
		private processService: ProcessService,
		private processNodeSupplier: DoiNavNodeChildSupplier,
		private psidAccessor: () => number)
	{
		super();
	}

	readChildren(node: DoiNavNode): Observable<DoiNavNode[]>
	{
		let psid = this.psidAccessor();
		if (!psid)
			return EMPTY;

		let criteria = new DoiSelectionCriteria()
			.select('Process_ProcessID', 'Process_Code', 'Process_NameList')
			.where('Process_ProcStructID', '='+psid)
			.and('Process_ParentProcessID', '=')
			.orderBy('Process_Code', 'Process_ProcessID');

		return this.processService.readObjectSelection(null, criteria).pipe(
			map((processes: ProcessObject[]) =>
			{
				let nodes = new Array<DoiNavNode>();
				for (let process of processes) {
					process.processStructure = null;
					let objectRef = DoiObjectRef.forObject('Process', process.objectID);
					nodes.push(
						new DoiNavNode(process.objectTitle(), '/process/'+process.objectID)
							.setObjectRef(objectRef)
							.setIconName(process.iconName)
							.setIconMapper(node.getIconMapper())
							.setChildNodeSupplier(this.processNodeSupplier)
					);
				}
				return nodes;
			})
		);
	}
}
