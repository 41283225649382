import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';

import {DoiObjectRef, DoiService, naturalOrder} from '../doi/DoiModule';
import {DoiSearchCriteria, DoiSearchService, DoiSearchResult, DoiSearchResultEntry, DoiSearchView, DoiSearchToolComponent} from '../doi-search/DoiSearchModule';
import {DoiNavNode} from '../doi-navigator/DoiNavigatorModule';

import {KlaraDialogTopView} from '../klara/KlaraModule';

import {AppDialogView} from '../AppDialogView';

import {SearchView} from './SearchView';

@Component({
	templateUrl: 'SearchDialogView.html'
})
export class SearchDialogView extends SearchView
{
	/**
	 * Reference to the application view.
	 */
	get appView(): AppDialogView { return super.appView as AppDialogView };
	set appView(appView: AppDialogView) { super.appView = appView };

	/**
	 * The search tool, used instead of the standard form.
	 */
	@ViewChild(DoiSearchToolComponent)
	searchTool: DoiSearchToolComponent;

	/**
	 * Pending search text, received before the search tool.
	 */
	searchTextPending: string;

	/**
	 * Construct a new search view.
	 */
	constructor(doi: DoiService, searchService: DoiSearchService, route: ActivatedRoute)
	{
		super(doi, searchService, route);
	}

	/**
	 * Invoked after view init. Overridden to set the search text on the search tool component.
	 */
	ngAfterViewInit()
	{
		super.ngAfterViewInit();

		if (this.searchTextPending !== undefined) {
			this.formSearchText = this.searchTextPending;
			this.searchTextPending = undefined;
			this.searchTool.focusFirst();
			this.searchAll();
		}
	}

	/**
	 * Build the form containing search text, word mode and count limit.
	 * Overridden since a simple search tool is used, not the standard form.
	 */
	buildForm()
	{
	}

	/**
	 * Return the format for the count text. Positional parameter 0 is the total count, and parameter 1 is the included count.
	 */
	countTextFormat(countTotal: number, countIncluded: number)
	{
		if (countTotal == 1)
			return countIncluded < countTotal ? 'Visar {1} av 1 träff' : 'Din sökning gav 1 träff';
		else
			return countIncluded < countTotal ? 'Visar {1} av {0} träffar' : 'Din sökning gav {0} träffar';
	}

	/**
	 * Test if the environment specifies that Klara Dialog is running.
	 */
	isAppDialog(): boolean
	{
		return true;
	}

	/**
	 * Test if the environment specifies that Klara WebUI is running.
	 */
	isAppWebUI(): boolean
	{
		return false;
	}

	/**
	 * The search text in the form.
	 */
	get formSearchText(): string
	{
		return this.searchTool ? this.searchTool.text: null;
	}
	set formSearchText(searchText: string)
	{
		if (this.searchTool)
			this.searchTool.text = searchText;
		else
			this.searchTextPending = searchText || null;
	}

	/**
	 * The search text in the form, if valid.
	 */
	get formSearchValidText(): string
	{
		return this.searchTool ? this.searchTool.validText: null;
	}

	/**
	 * Create a search criteria from query parameters.
	 */
	criteriaFromParams(pm: ParamMap): DoiSearchCriteria
	{
		let criteria = super.criteriaFromParams(pm);
		criteria.client = "Dialog";

		return criteria;
	}

	/**
	 * Create a search criteria from the current form values.
	 */
	criteriaFromForm(): DoiSearchCriteria
	{
		if (!this.appView.processStructure || !this.searchTool)
			return null;

		let criteria = new DoiSearchCriteria(this.searchTool.validText, 'ProcessStructure/'+this.appView.processStructure.procStructID);
		criteria.client = "Dialog";

		return criteria;
	}

	/**
	 * Return the corresponding path in Klara Dialog, or an empty string for the same URL.
	 */
	dialogPath() : string
	{
		return '';
	}

	/**
	 * Return the corresponding path in Klara WebUI, null if not available or an empty string for the same URL.
	 */
	klaraPath() : string
	{
		return '';
	}

	/**
	 * Populate the search form from a search criteria.
	 */
	populateForm(criteria: DoiSearchCriteria)
	{
		criteria.scope = null;

		this.formSearchText = criteria.text;
	}

	/**
	 * Process a search result delivered by the search service. Overridden to remove hidden process codes.
	 */
	processResult(result: DoiSearchResult)
	{
		if (this.appView && !this.appView.showProcessCodes()) {
			let codeRE = new RegExp('^[\\d\\.]+\\s+-\\s+');
			for (let catGroup of result.catGroups) {
				let done = false;
				switch (catGroup.name) {
					case 'Process':
						for (let cat of catGroup.cats) {
							let match = cat.text.match(codeRE);
							if (match) {
								cat.text = cat.text.substring(match[0].length);
							}
						}
						catGroup.cats.sort((c1, c2) => naturalOrder(c1.text, c2.text));
						done = true;
						break;
				}
				if (done)
					break;
			}
		}

		super.processResult(result);
	}

	searchCategoriesExpandClass(): string
	{
		return this.searchCategoriesHidden ? 'fa-chevron-down' : 'fa-chevron-up';
	}

	/**
	 * Return the object type names for the object types that should be searched. The default implementation returns null, for all supported object types.
	 */
	searchObjectTypes(): string[]
	{
		return ['Process', 'ProcessActType'];
	}
}
