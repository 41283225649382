import {Component, ViewChild, ElementRef} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EMPTY, forkJoin, Observable} from 'rxjs';
import {tap} from "rxjs/operators";

import {environment} from '../../environments/environment';

import {DoiBookmark, DoiObject, DoiSelectionCriteria, DoiService, DoiAction, DoiTopView} from '../doi/DoiModule';
import {DoiSearchToolComponent} from '../doi-search/DoiSearchModule';
import {AppInfo, ObjectFile, DocStoreLink} from '../klara/KlaraModule';
import {SystemInfoObject, SystemInfoService, SystemInfoPart} from '../systeminfo/SystemInfoModule';

import {AppDialogView} from '../AppDialogView';

import {ProcessService, ProcessObject} from '../process/ProcessModule';
import {ProcessStructurePart, ProcessStructureService, ProcessStructureObject} from '../processstructure/ProcessStructureModule';

import {StartBaseView} from './StartBaseView';
import { ArchiveOrigService } from '../archiveorig/ArchiveOrigModule';

@Component({
	templateUrl: 'StartDialogView.html',
})
export class StartDialogView extends StartBaseView
{
	/**
	 * Reference to the application view.
	 */
	get appView(): AppDialogView { return super.appView as AppDialogView };
	set appView(appView: AppDialogView) { super.appView = appView };

	/**
	 * Services.
	 */
	archiveOrigService: ArchiveOrigService;
	processStructureService: ProcessStructureService;
	processService: ProcessService;

	/**
	 * Top level processes.
	 */
	processes: ProcessObject[];

	/**
	 * References on the PS and the combined references.
	 */
	referencesPS: Array<ObjectFile>;
	private _referencesCombined: Array<ObjectFile>;

	/**
	 * ReferencesList expanded.
	 */
	private referencesListExpanded = false;

	/**
	 * Limitied ReferencesList.
	 */
	private _referencesCombinedLimited: Array<ObjectFile>;

	/**
	 * VisitedList expanded
	 */
	private visitedListExpanded = false;

	/**
	 * Limited VisitedList.
	 */
	private _visitedListLimited: DoiBookmark[];

	/**
	 * Construct a new view.
	 */
	constructor(appView: AppDialogView, doi: DoiService, route: ActivatedRoute, systemInfoService: SystemInfoService,
		archiveOrigService: ArchiveOrigService, processStructureService: ProcessStructureService, processService: ProcessService)
	{
		super(doi, route, systemInfoService);

		this.appView = appView;
		this.archiveOrigService = archiveOrigService;
		this.processStructureService = processStructureService;
		this.processService = processService;
	}

	/**
	 * Return the archive originator name.
	 */
	archOrigName(): string
	{
		if (this.appView.processStructure)
			return this.appView.processStructure.archiveOrigName;
	}

	/**
	 * Return the corresponding path in Klara WebUI, null if not available or an empty string for the same URL.
	 * This implementation causes navigation to the process structure page.
	 */
	klaraPath() : string
	{
		if (this.appView.processStructure)
			return '/processstructure/'+this.appView.processStructure.procStructID+'/general'
		else
			return '';
	}

	/**
	 * Return the logo URL.
	 */
	logoURL(): string
	{
		if (this.appView.processStructure)
			return this.archiveOrigService.logoURL(this.appView.processStructure.archiveOrigID);
		else
			return this.appView.logoURL();
	}

	/**
	 * Return the logo as a file URL if fetched, otherwise fetch it and return null.
	 */
	logoFileURL(): any
	{
		return this.archiveOrigService.logoCache.get(this.logoURL());
	}

	/**
	 * Invoked when path parameters are received.
	 */
	processParams(pm: ParamMap): void
	{
		let psid = pm.get('psid');
		if (psid)
			this.appView.selectProcessStructure(parseInt(psid));
	}

	/**
	 * Return the process text for the specified process.
	 */
	processText(process: ProcessObject)
	{
		return process.descriptionShort;
	}

	/**
	 * Test if a search tools should be shown in the application toolbar. Overridden to return true since the view doesn't have its own search field.
	 */
	searchToolVisible()
	{
		return true;
	}

	/**
	 * Test if the sidebar, with visited and references, should be shown.
	 */
	sidebarVisible(): boolean
	{
		let visitedList = this.visitedListEffective();
		if (visitedList && visitedList.length)
			return true;
		let references = this.referencesCombinedEffective();
		if (references && references.length)
			return true;
		return false;
	}

	/**
	 * Return the title.
	 */
	title(): string
	{
		if (this.systemInfo && this.systemInfo.dialogTitle)
			return this.systemInfo.dialogTitle;

		return null;
	}

	/**
	 * Return the subtitle.
	 */
	subtitle(): string
	{
		if (this.systemInfo && this.systemInfo.dialogSubTitle)
			return this.systemInfo.dialogSubTitle;

		return null;
	}

	/**
	 * Return the process structure grid label.
	 */
	gridLabel(): string
	{
		if (this.appView.processStructure && this.processes && this.processes.length)
			return 'Välj ett område';
		else if (this.appView.processStructure)
			return 'Det finns inga publicerade områden';
		else
			return 'Det finns ingen publicerad klassificeringsstruktur';

		return null;
	}

	/**
	 * Read top level processes.
	 */
	readTopLevelProcesses(): Observable<DoiObject[]>
	{
		let criteria = new DoiSelectionCriteria()
			.select('Process_ProcessID', 'Process_ProcStructID', 'Process_ProcessTypeID', 'Process_Code', 'Process_NameList', 'Process_DescriptionShort')
			.where('Process_ProcStructID', '='+this.appView.processStructure.procStructID)
			.and('Process_ParentProcessID', '=')
			.orderBy('Process_Code', 'Process_ProcStructID');

		return this.doi.brokerService('Process').readObjectSelection(null, criteria);
	}

	/**
	 * Return the observables to join when refreshing the view.
	 */
	refreshViewObservables(): Observable<any>[]
	{
		this.referencesPS = null;
		this._referencesCombined = null;
		this._referencesCombinedLimited = null;

		let observables = super.refreshViewObservables();

		if (this.appView.processStructure) {
			observables.push(
				this.readTopLevelProcesses().pipe(tap((processes: ProcessObject[]) => {
					this.processes = processes;
				})),
			);
			observables.push(
				this.processStructureService.readObjectPart(this.appView.processStructure.procStructID, ProcessStructurePart.ObjectFiles).pipe(
					tap((ps: ProcessStructureObject) => {
						this.referencesPS = ps.objectFiles;
						this._referencesCombined = null;
						this._referencesCombinedLimited = null;
					})
				)
			);
		}

		return observables;
	}

	/**
	 * Returnerar ikon för visa fler/färre
	 */
	referencesExpandIconClass()
	{
		return this.referencesListExpanded ? 'far fa-minus-circle' : 'far fa-plus-circle';
	}

	/**
	 * Returnerar text för visa fler/färre
	 */
	referencesExpandText()
	{
		return this.referencesListExpanded ? 'visa färre' : 'visa fler';
	}

	/**
	 * Return the combined system info references and the PS references.
	 */
	referencesCombined(): ObjectFile[]
	{
		if (!this._referencesCombined && this.references && this.referencesPS) {
			this._referencesCombined = new Array<ObjectFile>();
			this._referencesCombined.push(...this.references);
			this._referencesCombined.push(...this.referencesPS);
		}
		return this._referencesCombined;
	}

	/**
	 * Return limited combined system info references and the PS references
	 */
	referencesCombinedEffective(): ObjectFile[]
	{
		if (this.referencesListExpanded)
			return this.referencesCombined();

		if (!this.systemInfo)
			return null;

		if (!this._referencesCombinedLimited || this._referencesCombinedLimited.length <= 0) {
			if (this.truncateReferences())
				this._referencesCombinedLimited = this.referencesCombined().slice(0, this.systemInfo.showMaxRelated);
			else
				this._referencesCombinedLimited = this.referencesCombined();
		}

		return this._referencesCombinedLimited;
	}

	/**
	 * Return true if references truncated, otherwise false
	 */
	truncateReferences()
	{
		return this.systemInfo && this.systemInfo.showMaxRelated > 0 && this.referencesCombined().length > this.systemInfo.showMaxRelated;
	}

	setReferencesListExpanded()
	{
		this.referencesListExpanded = !this.referencesListExpanded;
	}

	/**
	 * Return limited visitid list
	 */
	visitedListEffective(): DoiBookmark[]
	{
		if (this.visitedListExpanded)
			return this.visitedList;

		if (!this.systemInfo)
			return null;

		if (!this._visitedListLimited || this._visitedListLimited.length <= 0) {
			if (this.truncateVisited())
				this._visitedListLimited = this.visitedList.slice(0, this.systemInfo.showMaxVisited);
			else
				this._visitedListLimited = this.visitedList;
		}

		return this._visitedListLimited;
	}

	/**
	 * Return true if visited truncates, otherwise false
	 */
	truncateVisited()
	{
		return this.systemInfo && this.systemInfo.showMaxVisited > 0 && this.visitedList.length > this.systemInfo.showMaxVisited;
	}

	/**
	 * Returnerar ikon för visa fler/färre
	 */
	visitedExpandIconClass()
	{
		return this.visitedListExpanded ? 'far fa-minus-circle' : 'far fa-plus-circle';
	}

	/**
	 * Returnerar text för att visa fler/färre
	 */
	visitedExpandText()
	{
		return this.visitedListExpanded ? 'visa färre' : 'visa fler';
	}

	setVisitedListExpanded()
	{
		this.visitedListExpanded = !this.visitedListExpanded;
	}

}
