<div class="doi-view start-view no-search-tool">

	<div class="doi-scroll">

		<div class="start-title">
			<div class="start-title-picture"></div>
			<div class="start-title-footer">
				<i><img [src]="logoFileURL()" alt="Logotyp"/></i>
				<span>{{archOrigName()}}</span>
			</div>
		</div>

		<div class="doi-subview">

			<div class="row start-body">
				<div class="col-12" *ngIf="title()">
					<h2>{{title()}}</h2>
				</div>
				<div class="col-12" *ngIf="subtitle()">
					<div class="start-subtitle">{{subtitle()}}</div>
				</div>
				<div class="col-12" [ngClass]="sidebarVisible() ? 'col-lg-8' : null">
					<div class="start-area">
						<h3>{{gridLabel()}}</h3>
						<div doi-object-grid [iconMapper]="this" [objects]="processes" [textSupplier]="processText"
							[cellsXL]="sidebarVisible() ? '6' :'4'" #focusFirst></div>
					</div>
				</div>
				<div class="col-12 col-lg-4" *ngIf="sidebarVisible()">
					<div class="row" *ngIf="referencesCombinedEffective()?.length">
						<div class="col-12 titled-list">
							<h3>Relaterade dokument</h3>
							<div *ngFor="let objectFile of referencesCombinedEffective()" class="object-files doi-object-list">
								<object-file [objectFile]="objectFile" [iconMapper]="this" (download)="downloadObjectFile(objectFile)"></object-file>
							</div>
							<div *ngIf="truncateReferences()" class="show-more">
								<a href="" (click)="setReferencesListExpanded()" >{{referencesExpandText()}}
									<i [ngClass]="iconClass(referencesExpandIconClass())"></i>
								</a>
							</div>
						</div>
					</div>
					<div class="row" *ngIf="visitedListEffective()?.length">
						<div class="col-12">
							<div class="doi-titled-list">
								<h3>Senast besökta sidor</h3>
								<ul class="doi-object-list">
									<li *ngFor="let bm of visitedListEffective()" [ngClass]="bm.objectType">
										<a [routerLink]="bm.path">
											<i [ngClass]="iconClass(bm.iconName)"></i>
											<span>{{bm.title}}</span>
										</a>
									</li>
								</ul>
								<div *ngIf="truncateVisited()" class="show-more">
									<a href="" (click)="setVisitedListExpanded()">{{visitedExpandText()}}
										<i [ngClass]="iconClass(visitedExpandIconClass())"></i>
									</a>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>

	</div>

</div>